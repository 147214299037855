.pult {
    /* position: fixed; */
    /* top: 150px; */
    z-index: 1;
    /* right: max(calc(45vw - 150px), 0px); */
    width: min(300px, 100vw);
    /* background-color: #80800041; */
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.arrow {
    padding: 10px;
    border-radius: 50%;
    background-color: #80800041;
    border: 1px solid #808000;
    cursor: pointer;
    width: 46px;
    height: 46px;
}
.arrowPrev {
    right: calc(50% + 10px);
}
.arrowNext {
    left: calc(50% + 10px);
}
.arrowClick {
    background-color: #808000;
}
