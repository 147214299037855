.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 0;
    margin: 0;
}

.box {
    background-color: #f5f5f580;
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px #0003, 0 1px 1px 0 #00000024, 0 1px 3px 0 #0000001f;
    transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    display: grid;
    gap: 10px 60px;
    padding: 20px 40px;
    grid-template-columns: 500px 300px;
}

.changePassword {
    background-color: inherit;
}

.title {
    grid-column: span 2;
    text-align: center;
    padding-right: 40px;
}

.infoPassword {
    font-size: 0.9em;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-block: 80px;
}
.infoPasswordForPhone {
    display: none;
    font-size: 0.9em;
}

@media only screen and (max-width: 1000px) {
    .box {
        width: 94vw;
        grid-template-columns: 1fr 300px;
    }
}
@media only screen and (max-width: 800px) {
    .box {
        width: 94vw;
        grid-template-columns: 1fr;
    }
    .infoPassword {
        display: none;
    }
    .infoPasswordForPhone {
        display: inline-block;
    }
}

.check {
    color: green;
}

.dont {
    /* color: rgb(255, 20, 20); */
    color: crimson;
}
