.bookingContainer {
    padding: 0 10px 10px 10px;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
    /* margin-bottom: 20px; */
}

.bookingKey {
    display: flex;
    padding-top: 5px;
    /* align-items: center; */
}
