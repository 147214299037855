*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.header {
  color: #fff;
  display: flex;
  justify-content: space-between;
}
