.container {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 30px;
}

.guests {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.guest {
    padding: 20px;
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.guestImage {
    border-radius: 50%;
    width: 120px;
}

.guestName {
    font-weight: 500;
}

.bookingContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.booking {
    display: grid;
    background-color: white;
    grid-template-columns: auto 1fr;
    gap: 10px;
    padding: 20px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.booking > hr {
    grid-column: 1 / 3;
    margin: 0;
}

.roomCategory {
    display: inline-block;
    border-radius: calc(5% + 5px);
    background-color: #66339988;
    font-weight: 400;
    font-size: 0.9em;
    padding-inline: 5px;
    color: white;
}

.transactions {
    grid-column: 1 / 3;
}

@media only screen and (max-width: 991px) {
    .container {
        display: flex;
        flex-direction: column;
    }

    .guests {
        flex-direction: row;
        gap: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .bookingContainer {
        display: flex;
        flex-direction: column;
    }
}
