.message-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
    margin: 10px auto;
}

.message-item {
    background-color: #fff;
    padding: 15px;
    border-radius: 3px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    cursor: pointer;
}
.message-item.new {
    border-left: 5px #33af00 solid;
}
.message-item:hover {
    transform: scale(1.02);
}

.message-title {
    font-size: 1rem;
    color: #333;
    margin-bottom: 5px;
    font-weight: bold;
}

.message-text {
    font-size: 0.9rem;
    color: #555;
}

.message-item .message-text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.message {
    padding: 15px;
    padding-top: 25px;
    border-radius: 3px;
}
