.hc-amenity {
    margin-inline: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
@media only screen and (max-width: 1100px) {
    .hc-amenity {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media only screen and (max-width: 800px) {
    .hc-amenity {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media only screen and (max-width: 500px) {
    .hc-amenity {
        grid-template-columns: repeat(1, 1fr);
    }
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}
