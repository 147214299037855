.hotel-logo-image {
    width: min(500px, 80%);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
}

#hli-file {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.li-div {
    height: 300px;
    width: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    justify-content: center;
}

.l-page {
    height: 30px;
    margin-top: 20px;
}
