h6 {
  margin-bottom: 0 !important;
}

.btn_wrapper {
  position: relative;
}

.btn_wrapper svg {
  position: absolute;
  top: 8px;
  right: 10px;
  cursor: pointer;
}

.pass_wrapper {
  position: relative;
}

.pass_wrapper svg {
  position: absolute;
  top: 22px;
  right: 10px;
  cursor: pointer;
}

.css-gadse4-MuiPaper-root-MuiGrid-root {
  padding: 10px;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  text-transform: capitalize !important;
}

.css-ke5b6m-MuiButtonBase-root-MuiButton-root {
  text-transform: capitalize !important;
}

.css-1vhaqj4-MuiButtonBase-root-MuiButton-root {
  text-transform: capitalize !important;
  margin-top: 10px !important;
  display: inline-block !important;
}

.Sign_title {
  font-size: 2rem !important;
  margin-bottom: 10px !important;
}

.logout_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-layout-header header css-dev-only-do-not-override-1adbn6x {
  padding: 0px !important;
}

.burger_icon_nav {
  color: #fff;
  cursor: pointer;
}

.log_btn {
  display: inline-block;
  padding: 15px 10px 13px 10px;
  outline: none;
  border: none;
  font-size: 17px;
}

.log_btn:hover {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  cursor: pointer;
}

.margin_top {
  margin-top: 21px !important;
}

@media only screen and (max-width: 600px) {
  .margin_top {
    margin-top: 0px !important;
  }

  .br {
    display: none;
  }

  .mobile_nane {
    display: none !important;
  }

  .modal_res {
    width: 100% !important;
  }

  .btn-res {
    flex-direction: column;
    gap: 20px;
  }
}

.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root {
  font-size: 17px;
}

.right_left {
  display: flex;
  justify-content: space-between;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 12000;
  cursor: wait;
}

#test {
  width: 40px !important;
}

#login_logo {
  width: 60px;
}

#dontLogo {
  width: 40px !important;
}

.logo_wrapper {
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.css-t89xny-MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.ant-layout-contentcss-dev-only-do-not-override-1adbn6x {
  padding: 10px !important;
}

.space-between {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.css-1e9lk82-MuiListItemAvatar-root {
  flex: 1 !important;
}

.css-10hburv-MuiTypography-root {
  flex: 1 !important;
}

.margin_top_100 {
  margin-top: 100px !important;
}

.grid_border {
  border-radius: 5px 5px 5px 5px;
  border: 1px solid gray;
}

.tab .css-1ex1afd-MuiTableCell-root {
  font-weight: 500;
  color: rgb(178, 178, 178);
}

@media only screen and (max-width: 1270px) {
  .TableWIthBtn {
    flex-direction: column;
  }

  .TableWIthBtn_item_right {
    margin-top: 50px;
  }
}

.login_input {
  width: 500px;
  margin-top: 50px !important;
}

.register_input {
  width: 500px;
}

@media only screen and (max-width: 600px) {
  .login_input {
    width: 300px !important;
  }

  .register_input {
    width: 300px;
  }

  .navbar_personal_title {
    display: none !important;
  }

  .card-body-wrapper {
    flex-direction: column;
  }

  .checkin_optional_data_item_left {
    margin-right: 0px !important;
  }

  .checkin_optional_data_item_right {
    margin-left: 0px !important;
  }
}

.navbar_personal_title {
  width: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar_wrapper {
  display: flex;
  align-items: center;
  /* background-color: red; */
}

.modal_box {
  width: 70% !important;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly !important;
  align-items: center;
}

@media only screen and (max-width: 500px) {
  .modal_box {
    width: fit-content;
    display: flex;
    flex-direction: column;
    width: 90% !important;
  }
}

.hdp-overlay {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(180, 180, 180, 0.3);
  cursor: wait;
  display: inline-block;
  width: 100%;
  height: 100%;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bookingContainer div[style="width: 0px; height: 24px;"] {
  display: none;
}

#pdf {
  display: flex !important;
  justify-content: center !important;
  align-items: center !;
}

.responsive-search-wrapper {
  display: flex;
  align-items: center;
}

.Copyright_link {
  text-decoration-line: none;
}

.footer_all_right {
  display: flex;
  align-items: baseline;
}

.left_padding {
  margin-left: 16px;
}

.card-body-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1000px) {
  .responsive-search-wrapper {
    flex-direction: column;
  }

  .responsive-search-wrapper-item {
    margin: 7px auto;
  }
}

.home_bars {
  margin-top: 50px;
}

.chart_flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chart_bar_flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.home_bars_wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.margin_right {
  margin-right: 50px !important;
}

.chart_bar_flex {
  margin-top: 50px;
}

@media only screen and (max-width: 1250px) {
  .BarChart_width {
    width: 500px !important;
    height: 300px !important;
  }
}

#doc-nav-info {
  margin-top: 14px;
}

#file-name {
  display: none;
}

#pdf-controls {
  display: none;
}

/* Hotel image */
.category-image-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.category-image-box {
  width: var(--width);
  height: calc(1.2 * var(--width));
  border: 1px solid #aaa;
  border-radius: 5px;
  transition: 0.2s;
  position: relative;
}

.deleting-index {
  display: none;
}

.image-deleting>.category-image-box {
  padding: 5px;
  cursor: pointer;
}

.image-deleting .deleting-index {
  display: inline-block;
  cursor: inherit;
  text-align: center;
  line-height: 1.1;
  font-weight: 800;
  color: white;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  margin: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.deleting-index.check {
  background-color: cornflowerblue;
}

.deleting-index.not-check {
  border: 2px solid cornflowerblue;
  background-color: transparent;
}

.category-image-box>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.showing {
  position: absolute;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  top: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;

  opacity: 0;
  cursor: pointer;
  background-color: black;

  color: white;
  font-size: 1.5em;
  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;
}

.category-image-box:hover .showing {
  opacity: 0.4;
}

.modal-image {
  width: 100%;
  /* height: fit-content; */
}

.upload-image {
  border: 1px dashed black;
  border-radius: 0;
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 500;
  padding-bottom: 15px;
}

.upload-image:hover {
  border-color: cornflowerblue;
  cursor: pointer;
  color: cornflowerblue;
}

.upload-image>span:first-child {
  font-size: 2rem;
}

/* Hotel image */
.daily_report_input {
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.checkout-payment{
  margin-top: 20px;
}
.checkout-payment .MuiFormControlLabel-label, .checkout-payment .MuiRadio-root, .checkout-payment .MuiCheckbox-root{
  padding: 5px;
  font-size: 15px !important;
}
.icon-for-tooltip::after{
  background-color: #ffffffa8;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 5px;
  content: var(--numberofnotifications);
  font-size: small;
  color: rgb(138, 6, 6);
}
.roles_icon{
  font-size: large;
  width: 40px;
}