.icon {
    width: 35px;
    height: 35px;
    margin-right: 20px;
    background-color: #999;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.tooltip {
    position: absolute;
    background-color: #dc3545;
    bottom: 20px;
    left: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fade {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    box-sizing: border-box;
    transition: opacity 0.15s linear;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    outline: 0;
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
    /* background-color: rgba(0, 0, 0, 0.2);  */
}

.modal {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    box-sizing: border-box;
    position: fixed;
    margin: auto;
    padding: 7px 10px;
    transform: translate3d(0%, 0, 0);
    right: 0;
    top: 64px;
    background-color: rgba(240, 240, 240, 0.85);
    border: 2px solid rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    z-index: 1051;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out !important;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out !important;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out !important;
    transition: opacity 0.3s linear, right 0.3s ease-out !important;
    overflow-y: auto;
}

.container {
    cursor: pointer;
    margin: 5px;
    padding: 10px 15px;
}

.card,
.card:hover {
    color: inherit;
    text-decoration: none;
}

.title {
    font-weight: 600;
    color: rgba(0, 20, 40, 0.7);
}

.close {
    padding: 10px;
    cursor: pointer;
    font-weight: 700;
    font-size: 24px;
    border-radius: 50%;
}
