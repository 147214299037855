.fullPage {
    width: 100%;
    height: 100%;
    position: relative;
    padding-bottom: 20px;
}

.dataContainer {
    width: 100%;
    height: 100%;
    display: inline-block;
    min-height: 200px;
}

.noDataContainer {
    display: none;
    width: 100%;
    padding-top: 20px;
    text-align: center;
}

@media only screen and (max-width: 800px) {
    .dataContainer {
        display: none;
    }
    .noDataContainer {
        display: inline-block;
    }
}

.filter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 5px;
}

.categoryContainer {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 15px auto;
}

.numberDays {
    display: flex;
    align-items: center;
    gap: 10px;
}

.numberArrows {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bigContainer {
    display: grid;
    grid-template-columns: auto repeat(var(--times), 1fr);
}

.bigContainerHead {
    background-color: #80808025;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: small;
    font-weight: 500;
}

.dayContainer {
    display: inline-block;
    background-color: #80808025;
    padding: 0 5px;
    color: #444;
    font-weight: 500;
    font-size: small;
    text-align: center;
    text-overflow: clip;
    /* overflow: hidden;
    white-space: wrap; */

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.room {
    background-color: #80808025;

    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 26px;
    min-width: 150px;

    padding: 0 5px;
    font-size: 0.9em;
    font-weight: 500;
    color: #444;
    border: 1px solid rgba(0, 0, 0, 0.07);

    border-left: 5px solid;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.roomNumber {
    display: inline-block;
}

.roomCategory {
    display: inline-block;
    border-radius: calc(5% + 5px);
    background-color: #66339988;
    font-weight: 400;
    font-size: 0.9em;
    padding-inline: 5px;
    color: white;
}

/* sig'magan text uchun */
.itemChild,
.itemFirstDay {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #444;
}

.item {
    transition: 0.1s;
    position: relative;
}

.itemBorder {
    position: absolute;
    width: 100%;
    height: 100%;
    border: solid rgba(0, 0, 0, 0.07);
    border-top-width: 1px;
    border-bottom-width: 1px;
}

.itemChild,
.itemFirstDay {
    padding: 0 3px;
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.2);
}
.itemChild::after,
.itemFirstDay::after {
    content: " ";
    width: 25px;
    height: 10px;
    background-color: var(--bg-color);
    transform: rotate(45deg) scale(2, 1);
    z-index: 2;
    position: absolute;
    top: -5px;
    right: 0;
}
 
.itemChild {
    left: 50%;
}
.itemFirstDay {
    left: 0;
}

.headerBlock1 {
    display: flex;
    align-items: center;
    gap: 5px;
}

.pultContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}
.bookingContainer {
    margin-bottom: 5px;
    width: 100%;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 5px;
    transition: 0.2s;
}

.bookingGuest {
    text-transform: capitalize;
}
